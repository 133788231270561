import BaseNetwork from "./base-network";
import { LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, TABOOLA } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class Taboola extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Taboola";

  getNetworkIdentifier = (): NETWORK => TABOOLA;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ADS;

  alertOptions = () =>  [];

  isETLActivated(): boolean {
    return true;
  }

  connectionMethod(args: any): any {
    return {
      clientId: args.clientId,
      clientSecret: args.clientSecret
    }
  }
}

export default Taboola;
