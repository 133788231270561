import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, OUTBRAINS } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";

class Outbrains extends BaseNetwork{
  connectingMethod = (): LinkMethod => OAUTH_2;

  getName = (): string => "Outbrains";

  getNetworkIdentifier = (): NETWORK => OUTBRAINS;

  isAlertActivated = (): boolean => false;

  isAvailableForAll = (): boolean => false;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = (): string => ADS;

  isETLActivated(): boolean {
    return false;
  }

  alertOptions = () =>  [];

  connectionMethod(args: any): any {
    return {
      "username": args.username,
      "password": args.password
    }
  }
}

export default Outbrains;
