import React, {useEffect} from 'react';
import {useSegmentTrack} from "react-segment-analytics";
import qs from 'query-string';
import JWT from "../../services/auth/jwt";
import Loading from '../loading';
import GoogleLogin, {GoogleLoginResponse, useGoogleLogin } from 'react-google-login';
import {useRegisterFromGoogleMutation} from "../../hooks/user";
import LocalStorage from '../../services/storage/local';
import { AnalyticsBrowser } from "@segment/analytics-next";
import config from "../../config";

const Redirect = () => {

    const params = qs.parse(window.location.search);
    const hashParams = qs.parse(window.location.hash);
    const track = useSegmentTrack();
    const registerFromGoogle = useRegisterFromGoogleMutation();
    const analytics = AnalyticsBrowser.load({ writeKey: config.segment_key })

    console.log(hashParams);
    if(!params.redirectTo && !hashParams.scope){
        return <div>No redirection</div>
    }

    if(params.redirectTo){
        LocalStorage.set('REDIRECT_TO', params.redirectTo as string);
    }

    useEffect(() => {
        track("Ask for redirect", {uri: params.redirectTo})
    }, []);

    const jwt = JWT.getToken();
    if(jwt){
        window.location.href = params.redirectTo as string;
        return <Loading />
    }

    return <Loading>
        <GoogleLogin
        autoLoad={!hashParams.scope}
        clientId="105475051584-fs2e6vvtku92jnt698jbukgmmjhal63l.apps.googleusercontent.com"
        uxMode="redirect"
        isSignedIn={hashParams.scope === undefined ? false: true}
        onSuccess={ async(data) => {
            const googleResponse = (data as GoogleLoginResponse);
            await analytics.identify(null, {
                lastName: googleResponse.profileObj.familyName,
                firstName: googleResponse.profileObj.givenName,
                email: googleResponse.profileObj.email,
                'lifecyclestage' : 'lead',
                tags: [
                    'Lead',
                    'Template'
                ]
            });
            await track("Register from redirect", {email: googleResponse.profileObj.email});
            window.location.href = localStorage.getItem('REDIRECT_TO') as string;

        }
        }
    />
    </Loading>

};

export default Redirect;
