import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { networkImageFromURI } from "../../../ui/components/network";
import { APP_STORE_CONNECT, WOOCOMMERCE } from "../../../constant/network";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import { checkConnectionStatus } from "../../../services/helper";
import { XCircleIcon } from "@heroicons/react/solid";

const validation = Yup.object({
  vendorId: Yup.string().required(),
  issuerId: Yup.string().required(),
  consumerKey: Yup.string().required(),
  consumerSecret: Yup.string().required(),
})
const AppStoreConnect = () => {
  const query = parse(window.location.search)
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);
  const formik = useFormik({
    initialValues: {
      vendorId: '',
      issuerId: '',
      consumerKey: '',
      consumerSecret: '',
    },
    validationSchema: validation,
    async onSubmit(values){
      setLoading(true);
      setStatus(true);
      const returnValue = {
        key: values.consumerKey,
        secret: values.consumerSecret,
        options:{
          issuerId: values.issuerId,
          vendorId: values.vendorId
        }
      }
      try {
        const response = await checkConnectionStatus("app-store-connect", returnValue);
        if (response.getStatus()) {
          if (query.from && query.from === "google-data-studio") {
            const url = new URL(generatePath(window.location, "/oauth2/authorize/app-store-connect/response"));
            url.search = (new URLSearchParams({ ...values, success: "true" })).toString();
            window.location.replace(url);
          } else {
            window.location.search = stringify({ ...values, success: true });
          }
        } else {
          setStatus(false);
        }
      }catch (e) {
        setStatus(false);
      }finally {
        setLoading(false);
      }
    }
  })
  return (
    <div className="w-full h-full">
      <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
        <h1 className="text-3xl font-bold text-white">Catchr x AppStore Connect</h1>
      </div>
      {!status ? <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
            <div className="mt-2 text-sm text-red-700">
              Please review the information that you have submitted. We couldn't connect to your AppStoreConnect store.
            </div>
          </div>
        </div>
      </div> : null}
      <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
        <div className="flex">
          <div className="mr-4 flex-shrink-0">
            <img src={networkImageFromURI(APP_STORE_CONNECT)}
                 className="h-16 w-16 bg-white text-gray-300" />
          </div>
          <div>
            <h4 className="text-lg font-bold">{t("Connect to your AppStore Connect store")}</h4>
            <p className="mt-1">
              {t("In order to connect to your AppStore Connect store, we need to get some informations.")}
            </p>
            <p className="mt-1">
              You could find a tutorial about how to connect this integration to Catchr <a  target="_blank" className="underline font-bold" href="https://www.catchr.io/post/how-to-connect-apple-app-store-to-catchr">here</a>.
            </p>
          </div>
        </div>
        <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
          <Input
            label="Issuer ID :"
            id="issuerId"
            name="issuerId"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.issuerId}
            touched={formik.touched.issuerId}
            error={formik.errors.issuerId}
            required
          />
          <Input
            label="Vendor ID :"
            id="vendorId"
            name="vendorId"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.vendorId}
            touched={formik.touched.vendorId}
            error={formik.errors.vendorId}
            required
          />
          <Input
            label="Key ID :"
            id="consumerKey"
            name="consumerKey"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.consumerKey}
            touched={formik.touched.consumerKey}
            error={formik.errors.consumerKey}
            required
          />
          <Input
            label="Key Secret :"
            id="consumerSecret"
            name="consumerSecret"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.consumerSecret}
            touched={formik.touched.consumerSecret}
            error={formik.errors.consumerSecret}
            required
          />
          <Button loading={loading} full size="large" type="primary" htmlType="submit">
            Next
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AppStoreConnect;
