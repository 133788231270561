import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { CheckIcon, SelectorIcon, XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { classNames } from "../../services/helper";
import Logo from "../../ui/components/logo/solo-logo.svg";
import NetworkProvider from "../../config/network/network-provider";
import DestinationProvider from "../../config/destination/destination-provider";
import { API, GOOGLE_DATA_STUDIO, GOOGLE_SHEETS, POWERBI, TABLEAU } from "../../constant/destination";
import { AMAZON_ADS, FACEBOOK_ANALYTICS } from "../../constant/network";
import config from "../../config";
import { Listbox, Transition } from "@headlessui/react";
import Button from "../../ui/components/button/button";
import UserAPI from "../../services/repository/user/current-user";
import { NotificationManager } from "../../ui/components/notification/notification";
import {useSegmentTrack} from "react-segment-analytics";
import { useMe } from "../../hooks/user";

const faqs = [
  {
    id: 1,
    question: "What is the difference between accounts and data sources ?",
    answer:
      "The definition of an “account” varies by data source. E.g. on Facebook Ads, an account is an ads account and on Google Analytics, an account is a view."
  }, {
    id: 2,
    question: "Why there is additional cost for accounts on some data-source ?",
    answer:
      "We need to store some data for specific gateway like Amazon Ads. So you pay 10€ / 12€ for each accounts you connect to the platform."
  },
];

const ADDITIONAL_COST_DATA_SOURCE = [AMAZON_ADS];

const PricingPage = () => {
  const me = useMe();
  const { t } = useTranslation();
  const [featured, setFeatured] = useState<string|null>(null);
  const [period, setPeriod] = useState<"monthly" | "annual">("monthly");
  const [currency, setCurrency] = useState<"USD" | "EUR">("USD");
  const plansConfig = config.stripe.plans;
  const [saving, setSaving] = useState(false);
  const plans = [
    {
      stripeKey: "basic",
      name: "basic",
      title: "Basic",
      featured: featured === "basic",
      max: 3,
      priceMonthly: plansConfig.basic.monthly[currency].value,
      priceYearly: plansConfig.basic.annual[currency].value,
      mainFeatures: [
        { id: 1, value: "Up to 3 accounts" },
      ]
    },
    {
      stripeKey: "small",
      name: "small",
      title: "Small",
      featured: featured === "small",
      priceMonthly: plansConfig.small.monthly[currency].value,
      priceYearly: plansConfig.small.annual[currency].value,
      max: 5,
      mainFeatures: [
        { id: 1, value: "Up to 5 accounts" },
      ]
    },
    {
      stripeKey: "medium",
      name: "medium",
      title: "Medium",
      featured: featured === "medium",
      max: 10,
      priceMonthly: plansConfig.medium.monthly[currency].value,
      priceYearly: plansConfig.medium.annual[currency].value,
      mainFeatures: [
        { id: 1, value: "Up to 10 account" },
      ]
    },
    {
      stripeKey: "business",
      name: "business",
      title: "Business",
      max: 20,
      featured: featured === "business",
      priceMonthly: plansConfig.business.monthly[currency].value,
      priceYearly: plansConfig.business.annual[currency].value,
      mainFeatures: [
        { id: 1, value: "Up to 20 accounts" },
      ]
    },
    {
      stripeKey: "agency",
      name: "agency",
      max: 50,
      title: "Agency",
      featured: featured === "agency",
      priceMonthly: plansConfig.agency.monthly[currency].value,
      priceYearly: plansConfig.agency.annual[currency].value,
      mainFeatures: [
        { id: 1, value: "Up to 50 accounts" },
      ]
    }
  ];
  const features = [
    {
      title: "Unlimited Reporting",
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
    {
      title: "Unlimited user",
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
    {
      key: "Google Data Studio",
      title: <span className="flex items-center"><img
        src={DestinationProvider.getByPlatform(GOOGLE_DATA_STUDIO)?.destinationImage()}
        className="h-5 w-5 mr-2" /> {DestinationProvider.getByPlatform(GOOGLE_DATA_STUDIO)?.getName()}</span>,
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
    {
      key: "Google Sheets",
      title: <span className="flex items-center"><img
        src={DestinationProvider.getByPlatform(GOOGLE_SHEETS)?.destinationImage()}
        className="h-5 w-5 mr-2" /> {DestinationProvider.getByPlatform(GOOGLE_SHEETS)?.getName()}</span>,
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
    {
      key: "PowerBI",
      title: <span className="flex items-center"><img
        src={DestinationProvider.getByPlatform(POWERBI)?.destinationImage()}
        className="h-5 w-5 mr-2" /> {DestinationProvider.getByPlatform(POWERBI)?.getName()}</span>,
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
    {
      key: "tableau",
      title: <span className="flex items-center"><img
        src={DestinationProvider.getByPlatform(TABLEAU)?.destinationImage()}
        className="h-5 w-5 mr-2" /> {DestinationProvider.getByPlatform(TABLEAU)?.getName()}</span>,
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
    {
      key: "api",
      title: <span className="flex items-center"><img
        src={DestinationProvider.getByPlatform(API)?.destinationImage()}
        className="h-5 w-5 mr-2" /> {DestinationProvider.getByPlatform(API)?.getName()}</span>,
      tiers: [
        { title: "starter", featured: featured === "starter", value: true },
        { title: "popular", featured: featured === "popular", value: true },
        { title: "intermediate", featured: featured === "intermediate", value: true }
      ]
    },
  ];

  const perks = NetworkProvider.getAll().filter(elem => elem.isDataStudioActivate() && !(ADDITIONAL_COST_DATA_SOURCE.includes(elem.getNetworkIdentifier()))).map(elem => ({
    key: elem.getName(),
    title: <span className="flex items-center"><img alt={elem.getName()} src={elem.networkImage()}
                                                    className="h-5 w-5 mr-2" /> {elem.getName()}</span>,
    tiers: [
      { title: "starter", featured: featured === "starter", value: true },
      { title: "popular", featured: featured === "popular", value: true },
      { title: "intermediate", featured: featured === "intermediate", value: true }
    ]
  }));

  const additionalNetwork = NetworkProvider.getAll().filter(elem => ADDITIONAL_COST_DATA_SOURCE.includes(elem.getNetworkIdentifier())).map(elem => ({
    key: elem.getName(),
    title: <span className="flex items-center"><img alt={elem.getName()} src={elem.networkImage()}
                                                    className="h-5 w-5 mr-2" /> {elem.getName()}</span>,
    tiers: [
      { title: "starter", featured: featured === "starter", value: true },
      { title: "popular", featured: featured === "popular", value: true },
      { title: "intermediate", featured: featured === "intermediate", value: true }
    ]
  }));

  const currencies = [
    { id: "USD", text: "USD" },
    { id: "EUR", text: "EUR" }
  ];

  let plan = featured;

  const selectedPlan = plan ? plansConfig[plan as never][period][currency] : null;
  const track = useSegmentTrack();
  useEffect(() => {
    track('View subscribe');
  })
  return (
    <div className="bg-gray-50">
      <header className="z-50 absolute top-0 left-4 bg-purple-600">
        <nav className="max-w-7xl mx-auto px-4" aria-label="Top">
          <div className="w-full py-6 flex items-center justify-between border-b border-purple-500 lg:border-none">
            <div className="flex items-center">
              <Link to="/"><img
                className="h-8 w-auto"
                src={Logo}
                alt="Workflow"
              /></Link>
              <div className="hidden ml-10 space-x-8 lg:block">
              </div>
            </div>
            <div className="ml-10 space-x-4">
            </div>
          </div>
          <div className="mt-4 flex justify-center space-x-6 lg:hidden">

          </div>
        </nav>
      </header>

      <main>
        {/* Pricing section */}
        <div>
          <div className="relative bg-purple-600 pt-8">
            {/* Overlapping background */}
            <div aria-hidden="true" className="hidden absolute bg-gray-50 w-full h-6 bottom-0 lg:block" />
            <div className="relative max-w-2xl mx-auto px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-6xl">
                <span className="block lg:inline">Simple pricing,</span>
                <span className="block lg:inline">no commitment.</span>
              </h1>
              <p className="mt-4 text-xl text-purple-100">
                Everything you need, nothing you don't. Pick a plan that best suits your business.
              </p>
            </div>

            <h2 className="sr-only">Plans</h2>

            {/* Toggle */}
            <div className="max-w-2xl mx-auto flex justify-between">
              <div className="relative mt-4 flex justify-center sm:mt-4">
                <div className="bg-purple-700 p-0.5 rounded-lg flex">
                  <button
                    onClick={() => {
                      track("Switch period", {period: 'monthly'})
                      setPeriod("monthly")
                    }}
                    type="button"
                    className={classNames(period === "monthly" ? "relative bg-white py-2 px-6 border-purple-700 rounded-md shadow-sm text-sm font-medium text-purple-700 whitespace-nowrap hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-700 focus:ring-white focus:z-10" : "relative py-2 px-6 border border-transparent rounded-md text-sm font-medium text-purple-200 whitespace-nowrap hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-700 focus:ring-white focus:z-10")}
                  >
                    Monthly billing
                  </button>
                  <button
                    onClick={() => {

                      track("Switch period", {period: 'annual'});
                      setPeriod("annual")
                    }}
                    type="button"
                    className={classNames(period === "annual" ? "relative bg-white py-2 px-6 border-purple-700 rounded-md shadow-sm text-sm font-medium text-purple-700 whitespace-nowrap hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-700 focus:ring-white focus:z-10" : "relative py-2 px-6 border border-transparent rounded-md text-sm font-medium text-purple-200 whitespace-nowrap hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-700 focus:ring-white focus:z-10")}
                  >
                    Yearly billing
                  </button>
                </div>
              </div>
              <div className="relative w-2/3 mt-4 flex justify-center sm:mt-4">
                <Listbox value={currency} onChange={e => {
                  track("Switch currency", {currency: e})
                  setCurrency(e)
                }}>
                  {({ open }) => (
                    <>
                      <div className="relative w-2/3">
                        <Listbox.Button
                          className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <span className="block truncate">{currency}</span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {currencies.map((currencyItem) => (
                              <Listbox.Option
                                key={currencyItem.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? "text-white bg-indigo-600" : "text-gray-900",
                                    "cursor-default select-none relative py-2 pl-3 pr-9"
                                  )
                                }
                                value={currencyItem.id}
                              >
                                {({ selected, active }) => (
                                  <>
                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                          {currencyItem.text}
                        </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? "text-white" : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>

            <h4 className="text-white relative max-ww-2xl mx-auto pt-4 text-center  lg:max-w-7xl "> Get 2 month free for
              an annual plan !</h4>
            {/* Cards */}

            <p className="mt-4 text-xl text-purple-100 text-center w-full">
              Connected account email : <b>{me.data?.getEmail()}</b>
            </p>
            <p className="mt-4 text-xl text-purple-100 text-center w-full">
              Current account number used in the last 30 days : <b>{me.data?.getCompany().userNumberDataSourceForLast30days}</b>
            </p>
            <div className="relative mt-8 max-w-2xl mx-auto px-4 pb-8 sm:mt-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-0">
              {/* Decorative background */}
              <div
                aria-hidden="true"
                className="absolute top-4 bottom-6 left-8 right-8 inset-0  block"
              />

              <div className="relative gap-6 grid grid-cols-3">
                {plans.map((plan) => {
                  let userNumberDataSourceForLast30days = me.data?.getCompany().userNumberDataSourceForLast30days;
                  if(!userNumberDataSourceForLast30days){
                    userNumberDataSourceForLast30days = -1;
                  }
                  return (
                    <div
                      onClick={(e) => {
                        track("Select plan", { plan: plan.name });
                        e.preventDefault();
                        setFeatured(plan.name);
                        // @ts-ignore
                        document.getElementById('subscribe').scrollIntoView({behavior: 'smooth'});
                      }}
                      key={plan.title}
                      className={classNames(
                        plan.featured ? "bg-white ring-2 ring-purple-700 shadow-md" : "bg-purple-700",
                        "cursor-pointer pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12 relative"
                      )}
                    >
                      {userNumberDataSourceForLast30days > plan.max ?
                        <div className="absolute top-3 right-3 text-sm text-red-400">
                          More than current usage on 30 days
                        </div> : null}
                      <div>
                        <h3
                          className={classNames(
                            plan.featured ? "text-purple-600" : "text-white",
                            "text-sm font-semibold uppercase tracking-wide"
                          )}
                        >
                          {plan.title}
                        </h3>
                        <div
                          className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                          <div className="mt-3 flex items-center">
                            <p
                              className={classNames(
                                plan.featured ? "text-purple-600" : "text-white",
                                "text-4xl font-extrabold tracking-tight"
                              )}
                            >
                              {currency === "USD" ? "$" : "€"}{period === "monthly" ? plan.priceMonthly : plan.priceYearly}
                            </p>
                            <div className="ml-4">
                              <p className={classNames(plan.featured ? "text-gray-700" : "text-white", "text-sm")}>
                                {currency} / {period === "monthly" ? "mo" : "year"}
                              </p>
                              <p className={classNames(plan.featured ? "text-gray-500" : "text-purple-200", "text-sm")}>
                                {period === "monthly" ? <>Billed yearly
                                  ({currency === "USD" ? "$" : "€"}{plan.priceYearly})</> : <> Billed monthly
                                  ({currency === "USD" ? "$" : "€"}{plan.priceMonthly})</>}
                              </p>
                            </div>
                          </div>
                          <a

                            href="#"
                            className={classNames(
                              plan.featured
                                ? "bg-purple-600 text-white hover:bg-purple-700"
                                : "bg-white text-purple-600 hover:bg-purple-50",
                              "mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                            )}
                          >
                            {plan.featured ? "Selected" : `Select ${plan.title}`}
                          </a>
                        </div>
                      </div>
                      <h4 className="sr-only">Features</h4>
                      <ul
                        role="list"
                        className={classNames(
                          plan.featured
                            ? "border-gray-200 divide-gray-200"
                            : "border-purple-500 divide-purple-500 divide-opacity-75",
                          "mt-7 border-t divide-y lg:border-t-0"
                        )}
                      >
                        {plan.mainFeatures.map((mainFeature) => (
                          <li key={mainFeature.id} className="py-3 flex items-center">
                            <CheckIcon
                              className={classNames(
                                plan.featured ? "text-purple-500" : "text-purple-200",
                                "w-5 h-5 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                plan.featured ? "text-gray-600" : "text-white",
                                "ml-4 text-sm font-medium"
                              )}
                            >
                            {mainFeature.value}
                          </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:pt-12 lg:px-8 flex justify-between" id="subscribe">
            <div>
              <h2 className="text-xl font-bold text-gray-900">Total : </h2>
            </div>
            <div>
              <div>
                <h2 className="text-xl font-bold text-gray-900">{selectedPlan ? Intl.NumberFormat(undefined, {
                  style: "currency",
                  currency: currency
                }).format((selectedPlan as any).value): "Select a plan"}  </h2>
              </div>
              <div className="mt-4">
                <Button type="primary-purple" loading={saving} onClick={async () => {
                  try {
                    setSaving(true);
                    track("Go to payment page", {plan:  (selectedPlan as any).id});
                    const data = await UserAPI.getCheckoutSession({
                      currency: currency.toLowerCase(),
                      line_items: [
                        {
                          "price": (selectedPlan as any).id,
                          "quantity": 1
                        }
                      ]
                    });
                    window.location = data.data.url;
                  } catch (e) {
                    track("Unable to go to payment page", {e});
                    NotificationManager.error("Unable to get your subscription. Please ask your account manager", "An error occurred");
                  }
                  setSaving(false);
                }}>
                  {t("Subscribe")}
                </Button>
              </div>
            </div>
          </div>
          <section aria-labelledby="comparison-heading" className="block">
            <h2 id="comparison-heading" className="sr-only">
              Feature comparison
            </h2>

            <div className="mt-24 max-w-7xl mx-auto px-8">
              <div className="w-full border-t border-gray-200 flex items-stretch">
                <div className="-mt-px w-1/4 py-6 pr-4 flex items-end">
                  <h3 className="mt-auto text-sm font-bold text-gray-900">Catered for business</h3>
                </div>
                <h3 className="mt-4 text-sm font-bold text-gray-900 w-full text-center">Includes in all plans</h3>
              </div>

              <div className="relative">
                {/* Fake card backgrounds */}
                <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div className="w-1/4 pr-4" />
                  <div className="w-3/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                </div>

                <table className="relative w-full">
                  <caption className="sr-only">Business feature comparison</caption>
                  <thead>
                  <tr className="text-left">
                    <th scope="col">
                      <span className="sr-only">Feature</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                  {features.map((feature) => (
                    <tr key={feature.key as string || feature.title as string}>
                      <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                        {feature.title}
                      </th>
                      {[feature.tiers[0]].map((tier, index) => (
                        <td
                          key={tier.title}
                          className={classNames(
                            "relative w-3/4 py-0 text-center"
                          )}
                        >
                            <span className="relative w-full h-full py-3">
                              {typeof tier.value === "string" ? (
                                <span
                                  className={classNames(
                                    tier.featured ? "text-purple-600" : "text-gray-900",
                                    "text-sm font-medium"
                                  )}
                                >
                                  {tier.value}
                                </span>
                              ) : (
                                <>
                                  {tier.value === true ? (
                                    <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                                  ) : (
                                    <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                  )}

                                  <span className="sr-only">{tier.value === true ? "Yes" : "No"}</span>
                                </>
                              )}
                            </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>

                {/* Fake card borders */}
                <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div className={classNames(
                      "w-full h-full rounded-lg  ring-purple-600"
                    )} />
                  </div>
                </div>
              </div>

              <h3 className="mt-10 text-sm font-bold text-gray-900">Included Data Source</h3>

              <div className="mt-6 relative">
                {/* Fake card backgrounds */}
                <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div className="w-1/4 pr-4" />
                  <div className="w-3/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                </div>

                <table className="relative w-full">
                  <caption className="sr-only">Perk comparison</caption>
                  <thead>
                  <tr className="text-left">
                    <th scope="col">

                    </th>
                    <th scope="col">

                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                  {perks.map((perk) => (
                    <tr key={perk.key}>
                      <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                        {perk.title}
                      </th>
                      {[perk.tiers[0]].map((tier, index) => (
                        <td
                          key={tier.title}
                          className={classNames(
                            "relative w-3/4 py-0 text-center"
                          )}
                        >
                            <span className="relative w-full h-full py-3">
                              {tier.value === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                              ) : (
                                <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{tier.value === true ? "Yes" : "No"}</span>
                            </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>

                {/* Fake card borders */}
                <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div className={classNames(
                      "w-full h-full rounded-lg  ring-purple-600",
                    )} />
                  </div>
                </div>
              </div>

              <h3 className="mt-10 text-sm font-bold text-gray-900">Additional Cost Data Source</h3>

              <div className="mt-6 relative">
                {/* Fake card backgrounds */}
                <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div className="w-1/4 pr-4" />
                  <div className="w-3/4 px-4">
                    <div className="w-full h-full bg-white rounded-lg shadow" />
                  </div>
                </div>

                <table className="relative w-full">
                  <caption className="sr-only">Perk comparison</caption>
                  <thead>
                  <tr className="text-left">
                    <th scope="col">
                      <span className="sr-only">Perk</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                  {additionalNetwork.map((perk) => (
                    <tr key={perk.key}>
                      <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600">
                        {perk.title}
                      </th>
                      {[perk.tiers[0]].map((tier, index) => (
                        <td
                          key={tier.title}
                          className={classNames(
                            "relative w-3/4 py-0 text-center"
                          )}
                        >
                            <span className="relative w-full h-full py-3">
                              {tier.value === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-purple-600" aria-hidden="true" />
                              ) : (
                                <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{tier.value === true ? "Yes" : "No"}</span>
                            </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>

                {/* Fake card borders */}
                <div className="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div className="w-1/4 pr-4" />
                  <div className="w-1/4 px-4">
                    <div className={classNames(
                      "w-full h-full rounded-lg"
                    )} />
                  </div>
                  <div className="w-1/4 px-4">
                    <div className={classNames(
                      "w-full h-full rounded-lg"
                    )} />
                  </div>
                  <div className="w-1/4 pl-4">
                    <div className={classNames(
                      "w-full h-full rounded-lg",
                    )} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>



        {/* Logo cloud */}
        <div className="max-w-2xl mx-auto py-12 px-4 sm:px-6 lg:max-w-7xl lg:pt-12  lg:pb-32 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="https://tigrz.paris/wp-content/uploads/2020/01/tigrz_agence_marketing_b.png"
                   alt="Tigrz" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src="https://cdn.deguisetoi.fr/public/images/layout/1/logo.svg" alt="Deguise-toi" />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img
                className="h-12"
                src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                alt="Deguise-toi"
              />
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <img
                className="h-12"
                src="https://collectivetank.com/wp-content/uploads/2021/12/logo_small.png"
                alt="Transistor"
              />
            </div>
            <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
              <img
                className="h-12"
                src="https://assets.website-files.com/6329c152ceaf91793f290e5d/6329d85e1ff0340305df7ece_ikom-logo-nav.svg"
                alt="Workcation"
              />
            </div>
          </div>
        </div>

        {/* FAQs */}
        <section aria-labelledby="faq-heading" className="bg-gray-900">
          <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="max-w-2xl lg:mx-auto lg:text-center">
              <h2 id="faq-heading" className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                Frequently asked questions
              </h2>
              <p className="mt-4 text-gray-400">
                {t("Some of the most frequent question. If you have more, do not hesitate to get in touch with us.")}
              </p>
            </div>
            <div className="mt-20">
              <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="font-semibold text-white">{faq.question}</dt>
                    <dd className="mt-3 text-gray-400">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PricingPage;
