import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {stringify, parse} from 'query-string';
import { XCircleIcon } from "@heroicons/react/solid";
import { networkImageFromURI } from "../../../ui/components/network";
import { PIWIK } from "../../../constant/network";
import Input from "../../../ui/components/input/input";
import Button from "../../../ui/components/button/button";
import {generatePath} from "../index";
import {checkConnectionOAuth2Status} from "../../../services/helper";

const validation = Yup.object({
  domain: Yup.string().required(),
  clientId: Yup.string().required(),
  clientSecret: Yup.string().required()
})
const Piwik = () => {
  const query = parse(window.location.search)
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);

  const formik = useFormik({
    initialValues: {
      domain: '',
      clientId: '',
      clientSecret: ''
    },
    validationSchema: validation,
    async onSubmit(values){
      setLoading(true);
      const returnValue = {
        options:{
          domain: values.domain,
          clientId: values.clientId,
          clientSecret: values.clientSecret
        }
      }
      try {
        const response = await checkConnectionOAuth2Status("piwik", returnValue);
        if (response.getStatus()) {
          if (query.from && query.from === "google-data-studio") {
            const url = new URL(generatePath(window.location, "/oauth2/authorize/piwik/response"));
            url.search = (new URLSearchParams({ ...values, success: "true" })).toString();
            window.location.replace(url);
          } else {
            window.location.search = stringify({ ...values, success: true, code: "" });
          }
        } else {
          setStatus(false);
        }
      }catch (e) {
        setStatus(false);
      }

      setLoading(false);
    }
  })
  return (
      <div className="w-full h-full">
        <div className="bg-purple-600 h-16 text-white flex items-center justify-center">
          <h1 className="text-3xl font-bold text-white">Catchr x Piwik</h1>
        </div>
        {!status ? <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Unable to connect</h3>
              <div className="mt-2 text-sm text-red-700">
                Please review the information that you have submitted. We can't connect to your Piwik account.
              </div>
            </div>
          </div>
        </div> : null}
        <div className="h-full flex flex-col  py-12 sm:px-6 lg:px-8">
          <div className="flex">
            <div className="mr-4 flex-shrink-0">
              <img src={networkImageFromURI(PIWIK)}
                   className="h-16 w-16 bg-white text-gray-300" />
            </div>
            <div>
              <h4 className="text-lg font-bold">{t("Connect to your Piwik account")}</h4>
              <p className="mt-1">
                {t("In order to connect to your Piwik account, we need to get some infos.")}
              </p>
            </div>
          </div>
          <form className="space-y-6 mt-4" onSubmit={formik.handleSubmit}>
            <Input
                label="Domain :"
                id="domain"
                name="domain"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.domain}
                touched={formik.touched.domain}
                error={formik.errors.domain}
                required
            />
            <Input
                label="Client ID :"
                id="clientId"
                name="clientId"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.clientId}
                touched={formik.touched.clientId}
                error={formik.errors.clientId}
                required
            />
            <Input
                label="Client Secret :"
                id="clientSecret"
                name="clientSecret"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.clientSecret}
                touched={formik.touched.clientSecret}
                error={formik.errors.clientSecret}
                required
            />
            <Button loading={loading} full size="large" type="primary" htmlType="submit">
              Next
            </Button>
          </form>
        </div>
      </div>
  );
};

export default Piwik;
