import BaseNetwork from "./base-network";
import { APIKEY, LinkMethod, OAUTH_2 } from "../../constant/link-method";
import { NETWORK, ADROLL, TEADS } from "../../constant/network";
import { networkImageFromURI } from "../../ui/components/network";
import { ADS } from "../../constant/constant";
import { CONVERSION_TAG, SECURITY, URL_CHECKER, ADS as ADS_TYPE } from "../../constant/alert";

class Teads extends BaseNetwork{
  connectingMethod = (): LinkMethod => APIKEY;

  getName = (): string => "Teads";

  getNetworkIdentifier = (): NETWORK => TEADS;

  isAlertActivated = (): boolean => true;

  isAvailableForAll = (): boolean => true;

  isDataStudioActivate = (): boolean => true;

  networkImage(): string {
    return networkImageFromURI(this.getNetworkIdentifier());
  }

  categoryType = ()  => ADS;

  isETLActivated = () => false;

  alertOptions = () =>  [];

}

export default Teads;
